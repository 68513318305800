<template>
  <div class="education-panel">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Education</h2>
        </v-col>
      </v-row>

      <v-row dense>

        <v-col
          v-for="(item, i) in items"
          :key="i"
          xl="3"
          lg="4"
          md="6"
          sm="12"
        >
          <a
            :href="item.url"
            target="_blank"
          >
            <v-card
              class="edu-card"
              :color="item.bgcolor"
              dark
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="edu-title text-h5"
                    v-text="item.title"
                  ></v-card-title>

                  <v-card-subtitle
                    class="edu-subtitle"
                    v-text="item.description"
                  ></v-card-subtitle>

                  <v-card-actions>
                    <v-btn
                      class="edu-btn"
                      text
                    >
                      PLAY NOW
                    </v-btn>
                  </v-card-actions>
                </div>

                <v-avatar
                  class="edu-avatar ma-3"
                  size="168"
                  tile
                >
                  <v-img :src="item.avatar"></v-img>
                </v-avatar>
              </div>
            </v-card>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      items: [],
    }),
    mounted: async function() {
      const url = require('../config.json').dataURL
      const response = await fetch(`${url}/config.json`)
      const config = await response.json()
      this.items = config.education
      for (let item of this.items) {
        item.avatar = `${url}/${item.avatar}`
      }
    },
  }
</script>

<style lang="scss">
  .edu-card {
    height: 12rem;
    cursor: pointer;
  }
  .edu-card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .edu-title {
    position: absolute;
    top: 0;
    color: #ffffff;
  }
  .edu-subtitle {
    position: absolute;
    top: 4rem;
    max-width: calc(100% - 180px);
  }
  .edu-btn {
    position: absolute;
    bottom: -0.5rem;
    opacity: 0;
    transition: bottom 0.3s, opacity 0.3s;
  }
  .edu-card:hover {
    &::before {
      opacity: 0.6;
    }
    .edu-btn {
      bottom: 0.5rem;
      opacity: 1;
    }
  }
</style>
