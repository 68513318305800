<template>
  <div class="web-search-and-settings">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-toolbar
            class="web-search-toolbar"
            width="100%"
            dense
            floating
          >
            <v-text-field
              v-model="query"
              class="web-search-field"
              hide-details
              prepend-icon="mdi-magnify"
              single-line
              placeholder="Search Google"
              @keydown.enter="search(query); query = ''"
            ></v-text-field>

            <!-- Uncomment to enable settings menu -->
            <!-- <v-btn icon>
              <v-icon>mdi-cog</v-icon>
            </v-btn> -->
          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      query: ''
    }),
    methods: {
      search: function(query) {
        window.open('https://www.google.com/search?q=' + query, '_blank');
      }
    },
  }
</script>

<style lang="scss">
  .web-search-toolbar {
    > .v-toolbar__content {
      width: 100%;
    }
  }
</style>

<style lang="scss" scoped>
  .web-search-toolbar {
    border-radius: 2rem;
  }
</style>
