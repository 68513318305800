<template>
  <div class="video-panel">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Video</h2>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          v-for="(item, i) in items"
          :key="i"
          xl="2"
          lg="3"
          md="4"
          sm="6"
        >
          <a
            :href="item.url"
            target="_blank"
          >
            <v-card
              class="video-card"
              dark
            >
              <v-img
                class="video-img"
                :src="item.bgimage"
                max-height="160"
              >
                <v-card-title
                  class="video-title text-h5 pt-3 mb-5"
                  v-text="item.title"
                ></v-card-title>

                <v-card-actions>
                  <v-btn
                    class="video-btn"
                    text
                  >
                    OPEN APP
                  </v-btn>
                </v-card-actions>
              </v-img>
            </v-card>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    data: () => ({
      items: [],
    }),
    mounted: async function() {
      const url = require('../config.json').dataURL
      const response = await fetch(`${url}/config.json`)
      const config = await response.json()
      this.items = config.video
      for (let item of this.items) {
        item.bgimage = `${url}/${item.bgimage}`
      }
    },
  }
</script>

<style lang="scss">
  .video-card {
    cursor: pointer;
  }
  .video-img::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .video-title {
    position: absolute;
    top: -1rem;
    opacity: 0;
    transition: top 0.3s, opacity 0.3s;
  }
  .video-btn {
    position: absolute;
    bottom: -0.5rem;
    opacity: 0;
    transition: bottom 0.3s, opacity 0.3s;
  }
  .video-card:hover {
    .video-img::before {
      opacity: 0.6;
    }
    .video-title {
      top: 0rem;
      opacity: 1;
    }
    .video-btn {
      bottom: 0.5rem;
      opacity: 1;
    }
  }
</style>
