<template>
  <div class="home">
    <web-search-and-settings />
    <education-panel />
    <video-panel />
    <audio-panel />
  </div>
</template>

<script>
  import WebSearchAndSettings from '../components/WebSearchAndSettings'
  import EducationPanel from '../components/EducationPanel'
  import VideoPanel from '../components/VideoPanel'
  import AudioPanel from '../components/AudioPanel'

  export default {
    name: 'Home',

    components: {
      WebSearchAndSettings,
      EducationPanel,
      VideoPanel,
      AudioPanel,
    },
  }
</script>
